<template>
  <div>
    <b-modal
      id="add-image"
      ref="modal"
      header-class="headerModal"
      centered
      :title="`${$t('AddImage')} `"
      hide-footer
    >
      <AddFile
        ref="addFile1"
        :folder="'UserDocuments/UserProfiles'"
        :label-text="$t('AddYourImage')"
        :file-data-type="'OtherDocument'"
        :file-name="imageFile"
      />
      <div class="buttonsEverywhere">
        <button
          type="button"
          variant="primary"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t('Submit') }}
        </button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; ; background: white; color: black; border-color: white;font-weight: 400;"

          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>

      </div>
    </b-modal>
  </div>
</template>

<script>
import AddFile from '@/modals/AddFile.vue'

export default {
  components: {
    AddFile,
  },
  data() {
    return {
      imageFile: [],
    }
  },
  mounted() {
  },

  methods: {
    async onSubmit() {
      try {
        await this.$refs.addFile1.uploadFile();
        if (this.imageFile.length == 0) {
          return
        }
        this.$emit('addImage', this.imageFile)
        this.$refs.modal.hide()
        this.onReset()
      } catch (error) {
        console.error(error)
      }
    },
    onCancel() {
      this.$refs.modal.hide()
      this.onReset()
    },
    onReset() {
      this.imageFile = []
    },
  },
}
</script>
